














import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'

import { ArticleCard, ArticleCardLayout } from '../../../front/content/organisms/ArticleCard'
import {
  translateContentToArticleCard
} from '../../../front/content/organisms/ArticleCard/ArticleCard.helpers'

import { ArticlesTeaserModule } from '../ArticlesTeaser.contracts'

/**
 * Container component for the `ArticlesTeaserModuleUi`.
 *
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<ArticlesTeaserModuleUi>({
  name: 'ArticlesTeaserModuleUiStacked',
  components: { ArticleCard }
})
export class ArticlesTeaserModuleUi extends AbstractModuleUi<ArticlesTeaserModule> {
  public ArticleCardLayout = ArticleCardLayout

  public get articles () {
    if (!this.content.articles?.length) {
      return
    }

    return this.content.articles.map(translateContentToArticleCard)
  }

  public get heading () {
    if (!this.content.heading) {
      return
    }

    return this.content.heading
  }

  public get cta () {
    return this.content.cta
  }
}

export default ArticlesTeaserModuleUi
